import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Icon } from "../../components/Component";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { VerifyMail } from "../../app/api";  // Assuming you have a SendMail function
import Logo from "../../images/7search.png";
import LogoDark from "../../images/7searchdark.png";
import { Block, BlockContent, BlockHead, PreviewCard } from "../../components/Component";



const VerifyMailPage = (props) => {
  const { userId,token } = props.match.params;
  const [loader, setLoader] = useState(true);
  const [message, setMessage] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const history = useHistory();
  const [timer, setTimer] = useState(5);
  // Function to verify mail
  const verifyMail = async () => {
    try {
      setLoader(true);
      const response = await VerifyMail(userId,token); // Ensure the correct function is being used here
    //   console.log(response.code)
      if (response.success) {
        setMessage(response.message);
        setIsVerified(true);
        localStorage.setItem("mail_verified", 1);
      } else if (response.success === false) {
        setMessage(response.message);
        setIsVerified(false);
      } else {
        setMessage("Something went wrong. Please try again.");
        setIsVerified(false);
      }
    } catch (error) {
      console.error("Error verifying email:", error);
      setMessage("Something went wrong. Please try again.");
      setIsVerified(false);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isVerified) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      const redirect = setTimeout(() => {
        history.push("/");
      }, 5000);

      return () => {
        clearInterval(countdown);
        clearTimeout(redirect);
      };
    }
  }, [isVerified, history]);

  useEffect(() => {
    verifyMail();
  }, [userId]);

  return (
    <React.Fragment>
      <Head title="Verify Email" />

      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to="/" className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent className="text-center">
                {loader ? (
                  <p>Loading...</p>
                ) : isVerified ? (
                  <>
                    <Icon name="check-circle-cut" className="display-4 icon-success" />
                    <h4 className="my-2">{message}</h4>
                    <p className="mt-2">
                    Thank you for choosing 7Search PPC!
                    </p>
                    <p>
                      Redirecting to your <strong>Publisher Dashboard</strong> in 
                      <span style={{ color: "#007bff", fontWeight: "bold" }}> {timer} </span> 
                      seconds.
                    </p>
                  </>
                ) : (
                  <>
                    <img
                      className="logo-dark logo-img logo-img-lg my-2"
                      src="https://www.7searchppc.com/assets/images/mailimg/suspended.png"
                      alt="logo-dark"
                    />
                    <h4 className="my-2">{message}</h4>
                  </>
                )}
              </BlockContent>
            </BlockHead>
          </PreviewCard>
        </Block>
        <ToastContainer />
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};

export default VerifyMailPage;
